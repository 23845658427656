import React, { Fragment } from 'react'
import useMedia from 'use-media'
import Layout from '../layout'
import {
  SEO,
  Overview,
  Benefits,
  Tour,
  Feature,
  More,
  Action,
  Quantity,
  Plan,
} from '../components'

import ChattingTimeIcon from '../assets/icons/chatting-time.svg'
import CloudIcon from '../assets/icons/cloud.svg'
import PaintIcon from '../assets/icons/paint.svg'

import OpenAgentAppOgImage from '../assets/images/open-agent-app-seo-image.png'

const OpenAgentApp = () => {
  const isWide = useMedia({ minWidth: 870 })
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Open Agent App"
        description="A unified operating system for teams that value productivity"
        image={OpenAgentAppOgImage}
      />
      <div className="u-bg-black-3">
        <Overview
          title="Open Agent App"
          subtitle={
            <Fragment>
              A unified operating system <br /> for teams that value
              productivity
            </Fragment>
          }
          image={{ name: 'openAgentAppOverview', maxHeight: 535 }}
          line={{ position: 'right' }}
          features={[
            {
              title: 'Agent App JS SDK',
              description:
                'Interact with the Agent App environment with simple JavaScript interfaces. Create interactive elements in the UI.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }extending-ui/extending-agent-app/agent-app-sdk/`,
            },
            {
              title: (
                <Fragment>
                  <code style={{ fontWeight: 'normal' }}>&lt;iframe&gt;</code>{' '}
                  Apps
                </Fragment>
              ),
              description:
                'Embed apps and widgets in various locations of the Agent App. Provide new tools for users and agents.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }extending-ui/extending-agent-app#app-locations`,
            },
            {
              title: 'Design System',
              description:
                'Bootstrap your apps and widgets in no time with ready-to-use React components or pure CSS.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }extending-ui/extending-agent-app/design-system/`,
            },
          ]}
          button={{
            label: 'See Agent App Docs',
            link: `${
              process.env.GATSBY_DOCS_URL
            }extending-ui/extending-agent-app/`,
          }}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-2xl u-Pb-xs">
        <Feature
          title={
            <div className="u-Mt-2xl">
              One control center <br /> for the entire team
            </div>
          }
          description="Easy to integrate with 3rd party software and in-house solutions. Multiple integration interfaces allow for building an ideal working environment that helps to stay productive."
          image="oneControlCenter"
        />
        <Feature
          inverse
          title={
            <Fragment>
              Built with open-source <br /> Design System
            </Fragment>
          }
          description="Use the same building blocks we do. Build new features for LiveChat that look and feel exactly as if they were shipped natively. Don’t waste your time on developing the UI from scratch."
          image="designSystem"
          link={{
            label: 'See GitHub Project',
            to: `${process.env.GATSBY_GITHUB_URL}/design-system`,
          }}
        />
        <Feature
          title={
            <Fragment>
              Proudly handles 30M <br /> conversations every month
            </Fragment>
          }
          description="Hundreds of thousands of websites use our widget to provide the best quality of customer care. Our servers deal with millions of chats every week, all of them handled with the same Agent App."
          component={
            <Quantity
              value="+100,000"
              description="users are chatting via Agent App every day"
            />
          }
        />
      </div>
      <div className="u-bg-black2 u-Pt-xl u-Pb-xs">
        <Benefits
          content={[
            {
              icon: PaintIcon,
              label: 'Well-designed & battle-tested',
              description:
                'We belive in dogfooding. We’ve been using our products since day one.',
            },
            {
              icon: ChattingTimeIcon,
              label: 'Mobile-ready & snappy',
              description:
                "We're building and testing the Agent App with mobile devices in mind. ",
            },
            {
              icon: CloudIcon,

              label: 'Open for apps & integrations',
              description:
                'The Agent App is open for all kinds of extensions and customizations.',
            },
          ]}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-xl u-Pb-2xl">
        <Plan
          title={
            <Fragment>
              Software should help, <br /> not stand in the way
            </Fragment>
          }
          subtitle={
            <Fragment>
              We built Agent App so it supports efficient and productive <br />{' '}
              work of customer service reps.
            </Fragment>
          }
        />

        <Tour
          inverse
          features={[
            {
              image: 'openAgentAppTour1',
              title: 'Fully customizable widgets at hand',
              description:
                'Load helpful widgets right into Customer Details panel. Update information on the fly and provide interactive elements for Agents’ convenience.',
            },
            {
              image: 'openAgentAppTour2',
              title: 'Entire screen for your app',
              description:
                'Big apps require more space. Leverage the area of the entire Agent’s screen and mount your app right in the main dock. Add notification badges to draw attention.',
            },
            {
              image: 'openAgentAppTour3',
              title: 'Contextual integration interfaces',
              description:
                'Context is the king of usability. Provide smooth user experience with contextual buttons, widgets and other interactive elements.',
            },
          ]}
        />
        <div className="u-bg-black-3 u-Pt-xl u-Pb-xs">
          <Feature
            title="Looking for a challenge? Build your own Agent App"
            description="We provide a set of real-time Messaging APIs, which allow you to build an agent app from the ground up. Build chatting web apps for all kinds of purposes."
            button={{
              link: `${
                process.env.GATSBY_DOCS_URL
              }messaging/chat-sdk#simple-agent`,
              label: 'See Sample Agent App',
            }}
            image="customerSdk"
          />
        </div>
        <More
          label={
            <Fragment>
              See other
              <span className="u-text-mark u-border-red"> Platform </span>
              products
            </Fragment>
          }
          links={[
            {
              title: 'Programmable Chat',
              description:
                'Open messaging protocol for all types of communication.',
              link: '/programmable-chat/',
            },
            {
              title: 'Open Chat Widget',
              description:
                'Beautiful widget for demanding developers and designers.',
              link: '/omnichannel/',
            },
            {
              title: 'Reporting APIs',
              description:
                'Build data-driven dashboards and advanced reporting tools.',
              link: '/data-and-reporting/',
            },
          ]}
        />
      </div>
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="createLead"
          title={
            <>
              Make teams happy <br />
              with the tools they use
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: isWide ? 'Open Developer Console' : 'Open Dev Console',
          }}
          link={{
            link: `${
              process.env.GATSBY_DOCS_URL
            }extending-ui/extending-agent-app/`,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default OpenAgentApp
